import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LightProtectionReference } from '@shared/interfaces/light-protection-reference/light-protection-reference.interface';

export const lightProtectionReferences = createActionGroup({
  source: '[ LIGHT PROTECTION REFERENCES ]',
  events: {
    load: emptyProps(),
    'load success': props<{ references: Array<LightProtectionReference> }>(),
    'load reference': props<{ id: number }>(),
    'load reference success': props<{ reference: LightProtectionReference }>(),
    delete: props<{ reference: LightProtectionReference }>()
  }
});
