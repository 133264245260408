export const ROUTES = {
  HOME: '',
  NOT_FOUND: 'not-found',
  FORBIDDEN: 'forbidden',
  SERVER_ERROR: 'server-error',
  PROFILE: 'profile',
  COMBINATIONS: 'combinations',
  FILTERS: 'filters',
  VARIANTS: 'variants',
  ARTICLES: 'articles',
  GUIDE: 'guide',
  ABBREVIATIONS: 'abbreviations',
  IMPRINT: 'imprint',
  TERMS_OF_USE: 'terms-of-use',
  PRIVACY: 'privacy',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  LEGAL: 'legal',
  ADMINISTRATION: 'admin',
  ENTITIES: 'entities',
  DRUG_CATEGORY: 'drug-category',
  DRUG: 'drug',
  CARRIER_SOLUTION: 'carrier-solution',
  PRODUCT: 'product',
  REDIRECT_URI: 'auth/callback',
  LOGIN_URI: 'login',
  SILENT_REFRESH: 'silent-refresh',
  SYSTEM_NOTIFICATIONS: 'system-notifications'
};
