import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { environment } from '@environment/environment';
import { User } from '@shared/interfaces/user/user.interface';
import { UserInfo } from '@shared/interfaces/user/user-info.interface';
import { UserMe } from '@shared/interfaces/user/user-me.interface';
import { forkJoin, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private readonly httpService: HttpService) {}

  public getUser(): Observable<User> {
    return forkJoin([this.getUserInfo(), this.getUserMe()]).pipe(map(this.mapUserData));
  }

  private getUserInfo(): Observable<UserInfo> {
    return this.httpService.request('userInfo').get(environment.backendUrl);
  }

  private getUserMe(): Observable<UserMe> {
    return this.httpService.request('me').get(environment.backendMyProfileUrl);
  }

  private mapUserData([userInfo, userMe]: [UserInfo, UserMe]): User {
    return {
      ...userInfo,
      ...userMe
    };
  }
}
