import { createReducer, on } from '@ngrx/store';
import { CarrierSolution } from 'src/app/modules/shared/interfaces/entities/carrier-solution.interface';
import { Drug } from 'src/app/modules/shared/interfaces/entities/drug.interface';
import { DrugCategory } from 'src/app/modules/shared/interfaces/entities/drug-category.interface';
import { Product } from 'src/app/modules/shared/interfaces/entities/product.interface';

import {
  carrierSolutions,
  cleanUpTables,
  drugCategories,
  drugs,
  products,
  setTab
} from './admin-entities.actions';

export const entitiesFeatureKey = 'entities';

export interface AdminEntitiesState {
  drugCategories: Array<DrugCategory>;
  drugs: Array<Drug>;
  carrierSolutions: Array<CarrierSolution>;
  products: Array<Product>;
  loading: boolean;
  tab: number;
}

export const initialState: AdminEntitiesState = {
  drugCategories: [],
  drugs: [],
  carrierSolutions: [],
  products: [],
  loading: false,
  tab: 0
};

export const adminEntitiesReducer = createReducer<AdminEntitiesState>(
  initialState,
  on(
    drugCategories.load,
    drugs.load,
    carrierSolutions.load,
    products.load,
    (state: AdminEntitiesState) => ({
      ...state,
      loading: true
    })
  ),
  on(
    drugCategories.loadSuccess,
    (state: AdminEntitiesState, data: { drugCategories: Array<DrugCategory> }) => ({
      ...state,
      drugCategories: data.drugCategories,
      loading: false
    })
  ),
  on(drugs.loadSuccess, (state: AdminEntitiesState, data: { drugs: Array<Drug> }) => ({
    ...state,
    drugs: data.drugs,
    loading: false
  })),
  on(
    carrierSolutions.loadSuccess,
    (state: AdminEntitiesState, data: { carrierSolutions: Array<CarrierSolution> }) => ({
      ...state,
      carrierSolutions: data.carrierSolutions,
      loading: false
    })
  ),
  on(products.loadSuccess, (state: AdminEntitiesState, data: { products: Array<Product> }) => ({
    ...state,
    products: data.products,
    loading: false
  })),
  on(setTab, (state: AdminEntitiesState, data: { tabIndex: number }) => ({
    ...state,
    tab: data.tabIndex
  })),
  on(cleanUpTables, (state: AdminEntitiesState) => ({
    ...state,
    drugCategories: [],
    drugs: [],
    carrierSolutions: [],
    products: [],
    loading: false
  }))
);
