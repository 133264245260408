export const TEXT = {
  ARTICLE: 'Lab Report',
  ARTICLES: 'Lab Reports',
  TEST_SUMMARY: 'Test Summary',
  DRUG_CATEGORY: 'Drug Category',
  DRUG_CATEGORIES: 'Drug Categories',
  DRUG: 'Drug',
  DRUGS: 'Drugs',
  CARRIER_SOLUTION: 'Carrier Solution',
  CARRIER_SOLUTIONS: 'Carrier Solutions',
  PRODUCT: 'B. Braun Product',
  PRODUCTS: 'B. Braun Products',
  PRODUCT_DETAILS: 'Product URL',
  COMBINATION: 'Combination',
  COMBINATIONS: 'Combinations',
  CONCENTRATION: 'Concentration',
  CONCENTRATION_RANGE: '(Range)',
  REFRIGERATED: 'Refrigerated',
  REFRIGERATED_DEGREE: '(2°C - 8°C)',
  ROOM_TEMPERATURE: 'Room Temperature',
  ROOM_TEMPERATURE_DEGREE: '(15°C - 25°C)',
  ADMINISTRATION_TEMPERATURE: 'Administration Temperature',
  ADMINISTRATION_TEMPERATURE_DEGREE: '(32°C)',
  STORAGE_CONDITION: 'Storage Condition',
  TITLE: 'Title',
  TEST_FACILITY: 'Test Facility',
  RELEASE_DATE: 'Release Date',
  CODE: 'Code',
  DRUG_STABILITY: 'Drug Stability',
  DRUG_STABILITY_LIST: 'Drug Stability / Compatibility List',
  HOURS: 'Hours',
  DAYS: 'Days',
  SEPARATE: 'Separate',
  COMBINED: 'Combined',
  VARIANT: 'Variant',
  YES: 'Accept',
  NO: 'Cancel',
  ENTITY_BASIC_ERROR: 'should be 3 characters and less than 255',
  NO_COMBINATIONS_FOUND: 'No combinations found.',
  PLEASE_SELECT_FILTER: 'Please use the filter options on top to receive some results.',
  NO_ENTITIES: 'There are no Entities of this type yet.',
  NO_LAB_REPORTS: 'There are no Lab Reports yet.',
  NO_VARIANTS: 'There are no stable / compatible drugs yet.',
  ERROR_TIME: 'Please enter only a number or `-`',
  DELETE: 'Delete',
  SAVE: 'Save',
  INSTRUCTION: {
    IMAGES: {
      FILTER: 'Select the filter options to get the desired results.',
      RESULT: 'The results will be displayed here.',
      DRUG_STABILITY: 'The stability of the drug is displayed here.',
      LAB_REPORT: 'The lab report is displayed here.',
      RESET_FILTER: 'Reset the filter options to get all results.'
    }
  }
};
