<cx-header>
  <cx-header-title>
    <h1>{{ title }}</h1>
  </cx-header-title>
  @if(showSearchbar) {
  <cx-search-bar
    [placeholder]="'Search'"
    (search)="search.emit($event)"
  ></cx-search-bar>
  }
</cx-header>
