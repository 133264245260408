import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ROUTES } from '@shared/constants/routes/routes.constants';
import { UserService } from '@shared/service/user/user.service';
import { catchError, map, Observable, of } from 'rxjs';
import { UserInfo } from "@shared/interfaces/user/user-info.interface";

@Injectable()
export class AdminGuard {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Observable<boolean> {
    return this.userService.getUserInfo().pipe(
      map((user: UserInfo) => {
        if (user?.admin) {
          return true;
        }
        this.router.navigateByUrl('/' + ROUTES.FORBIDDEN);
        return false;
      }),
      catchError(() => {
        this.router.navigateByUrl('/' + ROUTES.HOME);
        return of(false);
      })
    );
  }
}
