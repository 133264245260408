import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CxHeartBeatModule } from '@bbraun/cortex/carousel';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { CxSnackbarModule } from '@bbraun/cortex/snackbar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@shared/shared.module';
import { ApplicationEffects } from '@store/application/application.effects';
import { UserFacade } from '@store/user/user.facade';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { UserRoleComponent } from './components/user-role/user-role.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthModule } from './modules/auth/auth.module';
import { SystemNotificationsModule } from './modules/system-notifications/system-notifications.module';
import { reducers } from './store';

@NgModule({
  declarations: [AppComponent, UserRoleComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule.forRoot(),
    SharedModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true
      }
    }),
    EffectsModule.forRoot([ApplicationEffects]),
    SystemNotificationsModule,
    CxSnackbarModule,
    CxDialogModule,
    CxHeartBeatModule
  ],
  providers: [AdminGuard, UserFacade, provideHttpClient(withInterceptorsFromDi())],
  bootstrap: [AppComponent]
})
export class AppModule {}
