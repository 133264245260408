import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { MsalModule } from '@azure/msal-angular';
import { CxMaterialConfigProviders } from '@bbraun/cortex';
import { CxHeartBeatModule } from '@bbraun/cortex/carousel';
import { CxFloatingButtonModule } from '@bbraun/cortex/floating-button';
import { CxFooterModule } from '@bbraun/cortex/footer';
import { CxHeaderModule } from '@bbraun/cortex/header';
import { CxMenuModule } from '@bbraun/cortex/menu';
import { CxProfileDropdownComponent } from '@bbraun/cortex/profile-dropdown';
import { CxSearchBarModule } from '@bbraun/cortex/search-bar';
import { HttpModule, HttpService } from '@bbraun/helpex/src/lib/http';
import {
  MSAL_PROVIDERS,
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory
} from '@shared/service/auth/app-msal-factory.config';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AdminFooterComponent } from './components/admin-footer/admin-footer.component';
import { AutocompleteFilterComponent } from './components/autocomplete/autocomplete-filter/autocomplete-filter.component';
import { AutocompleteFilterControlComponent } from './components/autocomplete/autocomplete-filter-control/autocomplete-filter-control.component';
import { AutocompleteFiltersComponent } from './components/autocomplete/autocomplete-filters/autocomplete-filters.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { SearchHeaderComponent } from './components/search-header/search-header.component';
import { APP_DATE_FORMATS, AppDateAdapter } from './constants/date-picker/date-picker.constants';
import { DefaultImageDirective } from './directives/default-image.directive';
import { ScrollToFixedDirective } from './directives/scroll-to-fixed.directive';
import { TopMarginDirective } from './directives/top-margin.directive';
import { HeaderInterceptor } from './interceptors/https.interceptor';
import { ItemHeaderComponent } from './item-header/item-header.component';
import { CombinationResolver } from './resolvers/combination.resolver';
import { FiltersResolver } from './resolvers/filters.resolver';
import { CombinationsService } from './service/combinations/combinations.service';
import { EntitiesService } from './service/entities/entities.service';
import { HandleResponseService } from './service/handle-response/handle-response.service';
import { HomeService } from './service/home/home.service';
import { SmallHeaderComponent } from './small-header/small-header.component';

const modules = [
  // Material Modules
  FormsModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCardModule,
  MatTabsModule,
  // Included because of table component
  InfiniteScrollModule,
  MatTableModule,
  MatSortModule,
  MatTooltipModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  // Form Modules
  FormsModule,
  ReactiveFormsModule,
  // Tg Modules
  CxHeaderModule,
  CxFooterModule,
  CxFloatingButtonModule,
  CxMenuModule,
  CxSearchBarModule,
  // Helpex Modules
  HttpModule,
  CxProfileDropdownComponent
];

const components = [
  AutocompleteFilterComponent,
  FooterComponent,
  SmallHeaderComponent,
  SearchHeaderComponent,
  ProfileMenuComponent,
  ItemHeaderComponent,
  AdminFooterComponent,
  AutocompleteFilterControlComponent,
  AutocompleteFiltersComponent
];

const directives = [ScrollToFixedDirective, TopMarginDirective, DefaultImageDirective];

@NgModule({
  imports: [
    ...modules,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    ),
    CommonModule,
    HttpClientModule,
    RouterModule,
    CxHeartBeatModule
  ],
  declarations: [...components, ...directives],
  exports: [...modules, ...directives, ...components, MsalModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    HttpService,
    HomeService,
    EntitiesService,
    HandleResponseService,
    CombinationsService,
    CombinationResolver,
    FiltersResolver,
    CxMaterialConfigProviders,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ...MSAL_PROVIDERS
  ]
})
export class SharedModule {}
