export const JIRA_REGISTRAION_TICKET = 'https://jira.bbraun.com/servicedesk/customer/portal/24';

export const BACKEND_URL_LOCAL = 'http://localhost:8080/backend';
export const BACKEND_URL = '/backend';
export const BACKEND_MY_PROFILE_URL = 'https://api.myprofile.test.account.bbraun.com/api';
export const BACKEND_PROD_MY_PROFILE_URL = 'https://api.myprofile.account.bbraun.com/api';

export const PROFILE_DEV_URL = 'https://myprofile.test.account.bbraun.com';
export const PROFILE_QAS_URL = 'https://myprofile.test.account.bbraun.com';
export const PROFILE_PROD_URL = 'https://myprofile.account.bbraun.com';

export const MSAL_AUTH_AUTHORITY =
  'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/';
export const MSAL_KNOWN_AUTHORITIES = ['https://test.account.bbraun.com/'];

export const MSAL_API_CONFIG_GPR_SCOPES = ['https://test.account.bbraun.com/auth/gpr/User.Read'];
export const MSAL_API_CONFIG_GPR_URI = 'https://api.myprofile.test.account.bbraun.com/api';

export const MSAL_API_CONFIG_CHECK_SCOPES = [
  'https://test.account.bbraun.com/auth/check/Relations.User.Read'
];
export const MSAL_API_CONFIG_CHECK_URI = 'https://api.test.account.bbraun.com/auth/check';

export const MSAL_API_CONFIG_BACKEND_SCOPES = [
  'https://test.account.bbraun.com/67f4547e-fc7f-4ad6-af16-d51c6c277733/read'
];
export const MSAL_LOCAL_API_CONFIG_BACKEND_URI = 'http://localhost:8080/backend';
export const MSAL_DEV_API_CONFIG_BACKEND_URI = 'https://concomp.dev.bbraun.cloud/backend';

export const MSAL_QAS_API_CONFIG_BACKEND_SCOPES = [
  'https://test.account.bbraun.com/7672aa3e-e897-4c72-8d65-2950be08cf45/read'
];
export const MSAL_QAS_API_CONFIG_BACKEND_URI = 'https://concomp.qas.bbraun.cloud/backend';

export const MSAL_PROD_AUTH_AUTHORITY =
  'https://account.bbraun.com/1fa01227-ce45-482e-a1dc-3bfd547280ef/B2C_1A__SIGNUP_SIGNIN_MFA/';
export const MSAL_PROD_KNOWN_AUTHORITIES = ['https://account.bbraun.com/'];

export const MSAL_PROD_API_CONFIG_GPR_SCOPES = ['https://account.bbraun.com/auth/gpr/User.Read'];
export const MSAL_PROD_API_CONFIG_GPR_URI = 'https://api.myprofile.account.bbraun.com/api/me';

export const MSAL_PROD_API_CONFIG_CHECK_SCOPES = [
  'https://account.bbraun.com/auth/check/Relations.User.Read'
];
export const MSAL_PROD_API_CONFIG_CHECK_URI = 'https://api.myprofile.account.bbraun.com/auth/check';

export const MSAL_PROD_API_CONFIG_BACKEND_SCOPES = [
  'https://account.bbraun.com/729b8cd2-70b3-4172-bf08-9efe2bd0e752/read'
];
export const MSAL_PROD_API_CONFIG_BACKEND_URI = 'https://concomp.bbraun.cloud/backend';
