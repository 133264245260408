import { Component, inject, OnInit } from '@angular/core';
import { TEXT } from '@shared/constants/text/text.constants';
import { SystemNotificationView } from '@shared/interfaces/system-notification/system-notification-view.interface';
import { SystemNotification } from '@shared/interfaces/system-notification/system-notification.interface';
import { SystemNotificationService } from '@shared/service/system-notification/system-notification.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrl: './system-notifications.component.sass'
})
export class SystemNotificationsComponent implements OnInit {
  protected readonly text = TEXT;
  protected systemNotifications$: Observable<Array<SystemNotificationView>>;
  private readonly systemNotificationService = inject(SystemNotificationService);

  public ngOnInit(): void {
    this.systemNotifications$ = this.systemNotificationService
      .getSystemNotifications()
      .pipe(
        map((notifications) => notifications.filter(this.activeFilter).map(this.addShowBanner))
      );
  }

  protected onAction(notification: SystemNotificationView): void {
    notification.showBanner = false;
    const dismissedNotifications = this.getDismissedNotifications();
    dismissedNotifications.push(notification.id);
    localStorage.setItem('dismissedNotifications', JSON.stringify(dismissedNotifications));
  }

  protected onClose(notification: SystemNotificationView): void {
    notification.showBanner = false;
  }

  private readonly activeFilter = (notification: SystemNotification): boolean => {
    return this.isActive(notification) && !this.isDismissed(notification);
  };

  private readonly addShowBanner = (notification: SystemNotification): SystemNotificationView => {
    return {
      ...notification,
      showBanner: true
    };
  };

  private isActive(notification: SystemNotification): boolean {
    return new Date(notification.endDate) > new Date();
  }

  private getDismissedNotifications(): Array<number> {
    const dismissed = localStorage.getItem('dismissedNotifications');
    return dismissed ? JSON.parse(dismissed) : [];
  }

  private isDismissed(notification: SystemNotification): boolean {
    const dismissedNotifications = this.getDismissedNotifications();
    return dismissedNotifications.includes(notification.id);
  }
}
