import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { CxBannerModule } from '@bbraun/cortex/banner';
import { SharedModule } from '@shared/shared.module';
import { BannerComponent } from './banner/banner.component';
import { IconPipe } from './pipes/icon.pipe';
import { SystemNotificationsComponent } from './system-notifications.component';

@NgModule({
  declarations: [SystemNotificationsComponent, BannerComponent, IconPipe],
  imports: [CommonModule, SharedModule, CxBannerModule, MatDividerModule],
  exports: [SystemNotificationsComponent]
})
export class SystemNotificationsModule {}
