import { LogLevel } from '@azure/msal-browser';

export function loggerCallback(logLevel: LogLevel, message: string) {
  switch (logLevel) {
    case LogLevel.Error:
      console.error(`MSAL Error: ${message}`);
      break;
    case LogLevel.Warning:
      console.warn(`MSAL Warning: ${message}`);
      break;
    case LogLevel.Info:
      console.info(`MSAL Info: ${message}`);
      break;
    case LogLevel.Verbose:
      console.debug(`MSAL Verbose: ${message}`);
      break;
    case LogLevel.Trace:
      console.trace(`MSAL Trace: ${message}`);
      break;
    default:
      console.log(`MSAL Log: ${message}`);
      break;
  }
}
