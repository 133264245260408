<cx-footer>
  <button
    mat-button
    routerLink="/"
    [routerLinkActive]="'green'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <mat-icon>home</mat-icon>
    Home
  </button>

  <button
    mat-button
    routerLink="{{ '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.ENTITIES }}"
    [routerLinkActive]="'green'"
  >
    <mat-icon>ballot</mat-icon>
    Entities
  </button>

  <button
    mat-button
    routerLink="{{ '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.COMBINATIONS }}"
    [routerLinkActive]="'green'"
  >
    <mat-icon>assignment</mat-icon>
    Combinations
  </button>

  <button
    mat-button
    routerLink="{{ '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.LIGHT_PROTECTION_REFERENCES }}"
    [routerLinkActive]="'green'"
  >
    <mat-icon>shield</mat-icon>
    References
  </button>

  <button
    mat-button
    routerLink="{{ '/' + ROUTES.ADMINISTRATION + '/' + ROUTES.SYSTEM_NOTIFICATIONS }}"
    [routerLinkActive]="'green'"
  >
    <mat-icon>edit_notifications</mat-icon>
    System Notifications
  </button>
</cx-footer>
