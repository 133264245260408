import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icon'
})
export class IconPipe implements PipeTransform {
  transform(type: string): string {
    switch (type) {
      case 'info':
        return 'info';
      case 'success':
        return 'check_circle';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
      case 'alternative':
        return 'new_releases';
      default:
        return 'info';
    }
  }
}

//'info' | 'success' | 'warning' | 'error' | 'alternative'
