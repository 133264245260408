import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '@environment/environment';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.angularOAuthConfig.issuer,

  logoutUrl: environment.angularOAuthConfig.logoutUrl,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/openid/callback',
  // redirectUri: window.location.origin + '/backend/openid/callback',
  // redirectUri: 'http://localhost:8080' + '/backend/openid/callback',
  postLogoutRedirectUri: window.location.origin,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh',
  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.angularOAuthConfig.clientId,
  dummyClientSecret: environment.angularOAuthConfig.secret,

  responseType: 'code',

  jwks: {},

  disableAtHashCheck: true,

  clearHashAfterLogin: false,

  // Set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a use case specific one
  // to delete scope: 'openid profile email offline_access api'.
  // However offline_access is not supported by ping fedarate so it returns
  // invalid_scope when trying to refresh the token.
  scope: environment.angularOAuthConfig.scope,

  showDebugInformation: !environment.production,

  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true,

  // refreshes auth token only once
  timeoutFactor: 1
};
