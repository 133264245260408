export const TEXT = {
  MENU: {
    ADMINISTRATION: 'Administration',
    DRUGS: 'Drugs',
    DRUG_CATEGORIES: 'Drug Categories',
    CARRIER_SOLUTIONS: 'Carrier Solutions',
    PRODUCTS: 'B. Braun Products',
    COMBINATIONS: 'Combinations',
    REFERENCES: 'References',
    USER: 'User',
    GUIDE: 'Guide',
    ABBREVIATIONS: 'Abbreviations',
    LEGAL_TERMS: 'Legal Terms',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_OF_USE: 'Terms of Use',
    IMPRINT: 'Imprint'
  },
  BACK_BUTTON: {
    EDIT: 'Edit',
    CREATE: 'Create'
  },
  ENTITIES: 'Entities',
  ARTICLE: 'Lab Report',
  ARTICLES: 'Lab Reports',
  TEST_SUMMARY: 'Test Summary',
  DRUG_CATEGORY: 'Drug Category',
  DRUG_CATEGORIES: 'Drug Categories',
  DRUG: 'Drug',
  DRUGS: 'Drugs',
  CARRIER_SOLUTION: 'Carrier Solution',
  CARRIER_SOLUTIONS: 'Carrier Solutions',
  PRODUCT: 'B. Braun Product',
  PRODUCTS: 'B. Braun Products',
  PRODUCT_DETAILS: 'Product URL',
  COMBINATION: 'Combination',
  COMBINATIONS: 'Combinations',
  CONCENTRATION: 'Concentration',
  CONCENTRATION_RANGE: '(Range)',
  REFRIGERATED: 'Refrigerated',
  REFRIGERATED_DEGREE: '(2°C - 8°C)',
  ROOM_TEMPERATURE: 'Room Temperature',
  ROOM_TEMPERATURE_DEGREE: '(15°C - 25°C)',
  ELEVATED_TEMPERATURE: 'Elevated Temperature',
  ELEVATED_TEMPERATURE_DEGREE: '(32°C +/- 1°C)',
  STORAGE_CONDITION: 'Storage Condition',
  OPEN_PRODUCT_DETAILS: 'Click to open product',
  TITLE: 'Title',
  TEST_FACILITY: 'Test Facility',
  RELEASE_DATE: 'Release Date',
  CODE: 'Code',
  DRUG_STABILITY: 'Drug Stability',
  DRUG_STABILITY_LIST: 'Drug Stability / Compatibility List',
  HOURS: 'Hours',
  DAYS: 'Days',
  SEPARATE: 'Separate',
  COMBINED: 'Combined',
  VARIANT: 'Variant',
  YES: 'Accept',
  NO: 'Cancel',
  ENTITY_BASIC_ERROR: 'should be 3 characters and less than 255',
  NO_COMBINATIONS_FOUND: 'No combinations found.',
  PLEASE_SELECT_FILTER: 'Please use the filter options on top to receive some results.',
  NO_ENTITIES: 'There are no Entities of this type yet.',
  NO_LAB_REPORTS: 'There are no Lab Reports yet.',
  NO_VARIANTS: 'There are no stable / compatible drugs yet.',
  ERROR_TIME: 'Please enter only a number or `-`',
  DELETE: 'Delete',
  BACK: 'Back',
  SAVE: 'Save',
  EDIT: 'Edit',
  CREATE: 'Create',
  EDIT_CONFIRMATION: 'Are you sure you want to edit the following',
  INSTRUCTION: {
    IMAGES: {
      FILTER: 'Select the filter options to get the desired results.',
      RESULT: 'The results will be displayed here.',
      DRUG_STABILITY: 'The stability of the drug is displayed here.',
      LAB_REPORT: 'The lab report is displayed here.',
      RESET_FILTER: 'Reset the filter options to get all results.'
    }
  },
  DRUGS_VIEW: {
    LIGHT_PROTECTION: 'Light Protection',
    DILUTION: 'Dilution',
    ADMINISTRATION: 'Administration',
    COMMENT: 'Comment',
    REFERENCES: 'References'
  },
  SYSTEM_NOTIFICATION: {
    SYSTEM_NOTIFICATION: 'System Notification',
    SYSTEM_NOTIFICATIONS: 'System Notifications',
    DONT_SHOW_AGAIN: "Don't show again",
    TABLE: {
      TYPE: 'Type',
      TITLE: 'Title',
      START_DATE: 'Start Date',
      END_DATE: 'End Date',
      ACTION: 'Action',
      LAST_CHANGE: 'Last Change',
      CHANGED_BY: 'Changed By',
      ADD_SYSTEM_NOTIFICATION: 'Add System Notification',
      DELETE: {
        TITLE: 'Delete System Notification',
        MESSAGE: 'Are you sure you want to delete this System Notification?'
      }
    },
    DETAIL: {
      NAME: 'Name',
      EMAIL: 'Email',
      COUNTRY: 'Country',
      PRODUCTS: 'B. Braun Products',
      FORM_VALIDATION: {
        REQUIRED: 'This field is required.',
        PRODUCT_CHECKBOX: 'Please select at least one product.'
      }
    }
  },
  LIGHT_PROTECTION_REFERENCES: {
    TITLE: 'Light Protection Recommendations',
    REFERENCES: 'References',
    REFERENCE: 'Reference',
    REFERENCE_NUMBER: 'Reference Number',
    REFERENCE_TEXT: 'Reference Text',
    ADD_REFERENCE: 'Add Reference',
    TABLE: {
      NUMBER: 'Number',
      REFERENCE_TEXT: 'Reference Text',
      DELETE: {
        TITLE: 'Delete Reference',
        MESSAGE: 'Are you sure you want to delete this Reference?'
      }
    },
    POPOVER: {
      HEADER: 'Disclaimer',
      CONTENT:
        'The light protection recommendations provided are solely for informational purposes and are not legally binding. For definitive and more details, please refer to specific drug summaries of product characteristics.',
      LEGEND_NA: 'n.a.',
      LEGEND_NA_TEXT: 'No information available',
      LEGEND_YES: 'Light Protection is recommended',
      LEGEND_NO: 'Light Protection is not necessary'
    }
  },
  SEARCH: 'Search'
};
