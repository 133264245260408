import { Component, EventEmitter, Input, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SystemNotificationType } from '@shared/interfaces/system-notification/system-notification-type.type';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.sass'
})
export class BannerComponent {
  @Input() message: string;
  @Input() title: string;
  @Input() actionLabel: string;
  @Input() type: SystemNotificationType = 'info';
  @Input() icon = 'info';
  @Input() dismissable = false;

  @Output() actionEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  constructor(private readonly ds: DomSanitizer) {}

  ngOnInit() {
    this.message = this.ds.sanitize(SecurityContext.HTML, this.message) || '';
  }

  onAction() {
    this.actionEvent.emit();
  }

  onClose() {
    this.closeEvent.emit();
  }
}
