<div
  class="cx-banner d-flex align-items-center cx-banner-{{ type }}"
  [class.cx-banner-padding]="title"
>
  <mat-icon class="cx-banner-icon mx-m flex-shrink-0">{{ icon }}</mat-icon>
  <div class="pr-m d-flex flex-column">
    <h3
      *ngIf="title"
      class="mb-s mt-0"
    >
      {{ title }}
    </h3>
    <p
      class="my-0"
      [class.cx-banner-padding]="!title"
    >
      @if (message) {
        <span [innerHTML]="message"></span>
      } @else {
        <ng-content />
      }
    </p>
  </div>
  <div class="ml-auto d-flex align-items-center flex-shrink-0">
    <button
      mat-button
      *ngIf="dismissable && actionLabel"
      [color]="type"
      data-cy="cx-banner-button"
      [class.mr-xs]="!dismissable"
      [class.mr-m]="dismissable"
      (click)="onAction()"
    >
      {{ actionLabel }}
    </button>
    <div class="d-flex align-items-center">
      <mat-divider
        vertical
        class="mr-sm cx-banner-divider"
      ></mat-divider>
      <button
        mat-icon-button
        class="cx-banner-close-btn"
        data-cy="cx-banner-close-button"
        (click)="onClose()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
