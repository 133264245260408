import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrl: './search-header.component.sass'
})
export class SearchHeaderComponent {
  @Input() title!: string;
  @Input() showSearchbar!: boolean;
  @Output() search = new EventEmitter<string>();
}
