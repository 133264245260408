import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { User } from '@shared/interfaces/user/user.interface';

import { State } from '../index';
import { setCombinationFilter, userActions } from './user.actions';
import * as userSelectors from './user.selects';

@Injectable()
export class UserFacade {
  readonly user$ = this.store.select(userSelectors.getUser);
  readonly combinationFilter$ = this.store.select(userSelectors.getCombinationFilter);
  readonly loading = this.store.select(userSelectors.getLoading);

  constructor(private store: Store<State>) {}

  public setCombinationFilter(filter: any): void {
    this.store.dispatch(setCombinationFilter({ filter }));
  }

  public userDataLoaded(user: User): void {
    this.store.dispatch(userActions.loadUserDataSuccess({ user }));
  }

  public userDataNotLoaded(): void {
    this.store.dispatch(userActions.loadUserDataError());
  }
}
