import { inject, Injectable } from '@angular/core';
import { HttpService } from '@bbraun/helpex/src/lib/http';
import { environment } from '@environment/environment';
import { SystemNotification } from '@shared/interfaces/system-notification/system-notification.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemNotificationService {
  private readonly httpService = inject(HttpService);
  private readonly systemNotificationRoute = 'notification';

  public getSystemNotifications(): Observable<SystemNotification[]> {
    return this.httpService.request(this.systemNotificationRoute).get(environment.backendUrl);
  }

  public getSystemNotificationById(systemNotificationId: number): Observable<SystemNotification> {
    return this.httpService
      .request(this.systemNotificationRoute + `/${systemNotificationId}`)
      .get(environment.backendUrl);
  }

  public putSystemNotification(
    systemNotification: SystemNotification
  ): Observable<SystemNotification> {
    return this.httpService
      .request(this.systemNotificationRoute)
      .body(systemNotification)
      .put(environment.backendUrl);
  }

  public postSystemNotification(
    systemNotification: Omit<SystemNotification, 'id'>
  ): Observable<SystemNotification> {
    return this.httpService
      .request(this.systemNotificationRoute)
      .body(systemNotification)
      .post(environment.backendUrl);
  }

  public deleteSystemNotification(systemNotificationId: number): Observable<void> {
    return this.httpService
      .request(this.systemNotificationRoute + `/${systemNotificationId}`)
      .delete(environment.backendUrl);
  }
}
