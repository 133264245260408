import { createReducer, on } from '@ngrx/store';
import { CombinationFilter } from '@shared/interfaces/combination/combination-filters/combination-filters.interface';

import { setCombinationFilter, userActions } from './user.actions';
import { UserInfo } from "@shared/interfaces/user/user-info.interface";

export interface UserState {
  user: UserInfo;
  loading: boolean;
  filter?: CombinationFilter;
}

export const initialState: UserState = {
  user: {
    name: '',
    admin: false,
    user: false,
    error: false
  },
  loading: true,
  filter: {} as CombinationFilter
};

export const userReducer = createReducer<UserState>(
  initialState,
  on(userActions.loadUserDataSuccess, (state: UserState, data: { user: UserInfo }) => ({
    ...state,
    user: data.user,
    loading: false
  })),
  on(userActions.loadUserDataError, (state: UserState) => ({
    ...state,
    user: { ...state.user, error: true },
    loading: false
  })),
  on(setCombinationFilter, (state: UserState, data: { filter?: CombinationFilter }) => ({
    ...state,
    filter: data.filter
  }))
);
