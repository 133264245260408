import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { ROUTES } from '@shared/constants/routes/routes.constants';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/* tslint:disable */
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private snackBar = inject(CxSnackbarService);
  private router = inject(Router);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // continue
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 401: {
                // Logout didn't work with PingFederate, so we can't do anything here
                break;
              }
              case 404: {
                this.router?.navigateByUrl('/' + ROUTES.NOT_FOUND);
                break;
              }
              case 500:
              case 504: {
                this.snackBar.error('Something went wrong');
                break;
              }
              case 503: {
                this.router?.navigateByUrl('/' + ROUTES.SERVER_ERROR);
                break;
              }
            }
          }
        }
      })
    );
  }
}
