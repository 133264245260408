import { Component, inject, OnInit } from '@angular/core';

import { environment } from '@environment/environment';
import { User } from '@shared/interfaces/user/user.interface';
import { OAuthService } from '@shared/service/auth/o-auth.service';
import { UserFacade } from '@store/user/user.facade';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.sass'
})
export class ProfileMenuComponent implements OnInit {
  protected user!: User;
  private readonly oAuthServie = inject(OAuthService);
  private readonly userFacade = inject(UserFacade);

  public ngOnInit(): void {
    this.userFacade.user$.subscribe((user: User) => {
      this.user = user;
    });
  }

  protected logout() {
    this.oAuthServie.logout();
  }

  protected goToProfile() {
    window.open(environment.profile, '_blank');
  }
}
