@if (systemNotifications$ | async; as systemNotifications) {
  @for (systemNotification of systemNotifications; track systemNotification.id) {
    @if (systemNotification.showBanner) {
      <app-banner
        [type]="systemNotification.type"
        [icon]="systemNotification.type | icon"
        [title]="systemNotification.title"
        [message]="systemNotification.message"
        [dismissable]="systemNotification.dontShowAgainEnabled"
        [actionLabel]="text.SYSTEM_NOTIFICATION.DONT_SHOW_AGAIN"
        (actionEvent)="onAction(systemNotification)"
        (closeEvent)="onClose(systemNotification)"
      ></app-banner>
    }
  }
}
