import { LogLevel } from '@azure/msal-browser';
import { ROUTES } from '@shared/constants/routes/routes.constants';
import {
  BACKEND_PROD_MY_PROFILE_URL,
  BACKEND_URL,
  MSAL_PROD_API_CONFIG_BACKEND_SCOPES,
  MSAL_PROD_API_CONFIG_BACKEND_URI,
  MSAL_PROD_API_CONFIG_CHECK_SCOPES,
  MSAL_PROD_API_CONFIG_CHECK_URI,
  MSAL_PROD_API_CONFIG_GPR_SCOPES,
  MSAL_PROD_API_CONFIG_GPR_URI,
  MSAL_PROD_AUTH_AUTHORITY,
  MSAL_PROD_KNOWN_AUTHORITIES,
  PROFILE_PROD_URL
} from '@shared/constants/url/url.constants';

export const environment = {
  production: true,
  environment: 'PROD',
  enableProdMode: true,
  backendUrl: BACKEND_URL,
  backendMyProfileUrl: BACKEND_PROD_MY_PROFILE_URL,
  msalConfig: {
    auth: {
      clientId: 'd82d5626-f058-4e17-9951-95694e169d9e',
      authority: MSAL_PROD_AUTH_AUTHORITY,
      knownAuthorities: MSAL_PROD_KNOWN_AUTHORITIES,
      redirectUri: window.location.origin + '/' + ROUTES.REDIRECT_URI,
      postLogoutRedirectUri: window.location.origin
    },
    loginFailedRoute: ROUTES.FORBIDDEN,
    logLevel: LogLevel.Error
  },
  angularOAuthConfig: {
    issuer: 'https://idp.bbraun.com',
    logoutUrl: 'https://idp.bbraun.com/logout',
    clientId: 'ConComp',
    secret: '4c0LbhOCgqbm3YanmKgi8TK9Ifd9pYiAbyadNEdPeVN7E0eZZ88Bh0qgp6oUvLgT',
    scope: 'openid profile email'
  },
  apiConfig: {
    gpr: {
      scopes: MSAL_PROD_API_CONFIG_GPR_SCOPES,
      uri: MSAL_PROD_API_CONFIG_GPR_URI
    },
    check: {
      scopes: MSAL_PROD_API_CONFIG_CHECK_SCOPES,
      uri: MSAL_PROD_API_CONFIG_CHECK_URI
    },
    customBackend: {
      scopes: MSAL_PROD_API_CONFIG_BACKEND_SCOPES,
      uri: MSAL_PROD_API_CONFIG_BACKEND_URI
    }
  },
  // instana config
  trackingUi: {
    enable: true,
    reportingUrl: 'https://eum-blue-saas.instana.io',
    apiKey: 'T7EJ9SbEQqGgTPosXCXbjA'
  },
  profile: PROFILE_PROD_URL
};
