import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { CombinationFilter } from '@shared/interfaces/combination/combination-filters/combination-filters.interface';
import { UserInfo } from "@shared/interfaces/user/user-info.interface";

export const userActions = createActionGroup({
  source: '[ USER ]',
  events: {
    'load user data success': props<{ user: UserInfo }>(),
    'load user data error': emptyProps()
  }
});

export const setCombinationFilter = createAction(
  '[ USER ] SET_COMBINATION_FILTER',
  props<{ filter?: CombinationFilter }>()
);
