import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { ROUTES } from '@shared/constants/routes/routes.constants';
import { UserInfo } from '@shared/interfaces/user/user-info.interface';
import { UserFacade } from '@store/user/user.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.sass']
})
export class UserRoleComponent implements OnInit {
  public user!: UserInfo;
  public ROUTES = ROUTES;
  public loading$!: Observable<boolean>;

  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly userFacade = inject(UserFacade);

  public ngOnInit(): void {
    this.loading$ = this.userFacade.loading;
    this.userFacade.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: UserInfo) => {
      this.user = user;
      if (this.user.user && !this.user.admin) {
        this.router?.navigateByUrl(ROUTES.COMBINATIONS);
      }
    });
  }
}
