import { createReducer, on } from '@ngrx/store';
import { SystemNotification } from '@shared/interfaces/system-notification/system-notification.interface';
import { systemNotifications } from './system-notifications.actions';

export const systemNotificationsFeatureKey = 'systemNotifications';

export interface SystemNotificationsState {
  systemNotifications: Array<SystemNotification>;
  systemNotification: SystemNotification;
  loading: boolean;
}

export const initialState: SystemNotificationsState = {
  systemNotifications: [],
  systemNotification: {} as SystemNotification,
  loading: false
};

export const systemNotificationsReducer = createReducer<SystemNotificationsState>(
  initialState,
  on(systemNotifications.load, (state: SystemNotificationsState) => ({
    ...state,
    loading: true
  })),
  on(
    systemNotifications.loadSuccess,
    (
      state: SystemNotificationsState,
      data: { systemNotifications: Array<SystemNotification> }
    ) => ({
      ...state,
      systemNotifications: data.systemNotifications,
      loading: false
    })
  ),
  on(systemNotifications.loadSystemNotification, (state: SystemNotificationsState) => ({
    ...state,
    loading: true
  })),
  on(
    systemNotifications.loadSystemNotificationSuccess,
    (state: SystemNotificationsState, data: { systemNotification: SystemNotification }) => ({
      ...state,
      lightProtectionreference: data.systemNotification,
      loading: false
    })
  )
);
