import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

import { INITIAL_REDIRECT_STATE } from '../auth.constants';

@Component({
  selector: 'app-redirect-endpoint',
  template: ''
})
export class RedirectEndpointComponent implements AfterViewChecked {
  wasNotCalled = true;

  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router
  ) {}

  ngAfterViewChecked(): void {
    if (this.oauthService.getIdToken() && this.oauthService.getAccessToken() && this.wasNotCalled) {
      let route = localStorage.getItem(INITIAL_REDIRECT_STATE)
        ? localStorage.getItem(INITIAL_REDIRECT_STATE)
        : '/';

      route = this.normalizePath(route!);

      this.wasNotCalled = false;
      this.router?.navigateByUrl(route);
    }
  }

  /**
   * Remove the /admin substring from the path in order to perform nested route redirection. There are no routes with /admin prefixes
   * defined in the application routing module routes. However, when we initially save the redirect state the window.location.pathname
   * includes /admin substring prefix (e.g. /admin/customer).
   *
   * @param path String - path used for redirection after login
   */
  private normalizePath(path: string): string {
    return path.replace('/admin', '');
  }
}
