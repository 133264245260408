<cx-footer>
  <button
    *ngIf="(userFacade.user$ | async)?.admin"
    mat-button
    routerLink="/"
    [routerLinkActive]="'green'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <mat-icon>home</mat-icon>
    Home
  </button>

  <button
    mat-button
    routerLink="{{ '/' + ROUTES.COMBINATIONS }}"
    [routerLinkActive]="'green'"
    [routerLinkActiveOptions]="{ exact: false }"
  >
    <mat-icon>assignment</mat-icon>
    Combinations
  </button>

  <button
    mat-button
    routerLink="{{ '/' + ROUTES.GUIDE }}"
    [routerLinkActive]="'green'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <mat-icon>help_outline</mat-icon>
    Guide
  </button>

  <button
    mat-button
    routerLink="{{ '/' + ROUTES.ABBREVIATIONS }}"
    [routerLinkActive]="'green'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <mat-icon>question_answer</mat-icon>
    Abbreviations
  </button>

  <button
    mat-button
    [matMenuTriggerFor]="menu"
  >
    <div class="d-flex align-items-center">
      Legal Terms
      <mat-icon class="ml-xs">arrow_drop_up</mat-icon>
    </div>
  </button>
</cx-footer>

<mat-menu #menu="matMenu">
  <a
    class="menu-item"
    routerLink="{{ '/' + ROUTES.LEGAL + '/' + ROUTES.IMPRINT }}"
    mat-menu-item
  >
    Imprint
  </a>
  <a
    class="menu-item"
    routerLink="{{ '/' + ROUTES.LEGAL + '/' + ROUTES.TERMS_OF_USE }}"
    mat-menu-item
  >
    Terms of Use
  </a>
  <a
    class="menu-item"
    routerLink="{{ '/' + ROUTES.LEGAL + '/' + ROUTES.PRIVACY }}"
    mat-menu-item
  >
    Privacy Policy
  </a>
</mat-menu>
