import { createReducer, on } from '@ngrx/store';
import { LightProtectionReference } from '@shared/interfaces/light-protection-reference/light-protection-reference.interface';
import { lightProtectionReferences } from './light-protection-references.actions';

export const lightProtectionReferencesFeatureKey = 'lightProtectionReferences';

export interface LightProtectionReferencesState {
  lightProtectionReferences: Array<LightProtectionReference>;
  lightProtectionreference: LightProtectionReference;
  loading: boolean;
}

export const initialState: LightProtectionReferencesState = {
  lightProtectionReferences: [],
  lightProtectionreference: {} as LightProtectionReference,
  loading: false
};

export const lightProtectionReferencesReducer = createReducer<LightProtectionReferencesState>(
  initialState,
  on(lightProtectionReferences.load, (state: LightProtectionReferencesState) => ({
    ...state,
    loading: true
  })),
  on(
    lightProtectionReferences.loadSuccess,
    (
      state: LightProtectionReferencesState,
      data: { references: Array<LightProtectionReference> }
    ) => ({
      ...state,
      lightProtectionReferences: data.references,
      loading: false
    })
  ),
  on(lightProtectionReferences.loadReference, (state: LightProtectionReferencesState) => ({
    ...state,
    loading: true
  })),
  on(
    lightProtectionReferences.loadReferenceSuccess,
    (state: LightProtectionReferencesState, data: { reference: LightProtectionReference }) => ({
      ...state,
      lightProtectionreference: data.reference,
      loading: false
    })
  )
);
