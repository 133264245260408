import { ActionReducerMap } from '@ngrx/store';

import * as fromAdminCombinations from './admin-combinations/admin-combinations.reducer';
import * as fromEntities from './admin-entities/admin-entities.reducer';
import * as fromApplication from './application/application.reducer';
import * as fromReferences from './light-protection-references/light-protection-references.reducer';
import * as fromSystemNotifications from './system-notifications/system-notifications.reducer';
import * as fromUserCombinations from './user-combinations/user-combinations.reducer';
import * as fromUser from './user/user.reducer';

export interface State {
  application: fromApplication.ApplicationState;
  userCombinations: fromUserCombinations.UserCombinationsState;
  user: fromUser.UserState;
  entities: fromEntities.AdminEntitiesState;
  adminCombinations: fromAdminCombinations.AdminCombinationsState;
  lightProtectionReferences: fromReferences.LightProtectionReferencesState;
  systemNotifications: fromSystemNotifications.SystemNotificationsState;
}

export const reducers: ActionReducerMap<State, any> = {
  application: fromApplication.applicationReducer,
  userCombinations: fromUserCombinations.userCombinationsReducer,
  user: fromUser.userReducer,
  entities: fromEntities.adminEntitiesReducer,
  adminCombinations: fromAdminCombinations.adminCombinationsReducer,
  lightProtectionReferences: fromReferences.lightProtectionReferencesReducer,
  systemNotifications: fromSystemNotifications.systemNotificationsReducer
};
