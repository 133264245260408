import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SystemNotification } from '@shared/interfaces/system-notification/system-notification.interface';

export const systemNotifications = createActionGroup({
  source: '[ SYSTEM NOTIFICATIONS ]',
  events: {
    load: emptyProps(),
    'load success': props<{ systemNotifications: Array<SystemNotification> }>(),
    'load system notification': props<{ id: number }>(),
    'load system notification success': props<{ systemNotification: SystemNotification }>(),
    delete: props<{ systemNotification: SystemNotification }>()
  }
});
