import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { environment } from '@environment/environment';
import { UserInfo } from '@shared/interfaces/user/user-info.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private readonly httpService: HttpService) {}

  getUserInfo(): Observable<UserInfo> {
    return this.httpService.request('userInfo').get(environment.backendUrl);
  }
}
