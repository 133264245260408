<mat-form-field class="autocomplete-container font-sm">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    type="text"
    required
    matInput
    [formControlName]="customControlName"
    class="autocomplete-input font-sm"
    [matAutocomplete]="auto"
    (input)="onInputValueChange($event)"
  />

  <mat-autocomplete
    class="autocomplete"
    #auto="matAutocomplete"
    autoActiveFirstOption
    (optionSelected)="optionSelected($event.option.value)"
  >
    <mat-option
      *ngFor="let value of filteredValues"
      class="opt font-sm"
      [value]="value"
    >
      {{ value }}
    </mat-option>
    <mat-option
      *ngIf="filteredValues?.length === 0"
      class="opt font-sm"
      disabled
    >
      No results found
    </mat-option>
  </mat-autocomplete>
  <mat-error>
    <span>
      Please
      <strong>select</strong>
      a value from the dropdown
    </span>
  </mat-error>
</mat-form-field>
